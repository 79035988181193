import { isP2PWithoutIframe } from '../../../../hooks/useCheckUser';
import { callGtm } from '../../GTM/';
document.addEventListener('readystatechange', event => {
    if (!window.location.pathname.includes('checkout-page') && document.readyState === 'complete') {
        const dynamicDataList = [{ title: 'My Favorites', mountingClass: 'P2P_React_Mount' }];
        var headerSearchMobileBtn = document.querySelector('.header__search__mobilebtn');
        headerSearchMobileBtn?.classList?.add('header__search__mobilebtn--show');
        var sideMenu = document.querySelector('.side__menu');
        var sideMenuButton = document.querySelector('.side__menu-button');
        var headerSearchClose = document.querySelector('.header__search__close');
        var headerCarousal = document.querySelector('.header__carousel-wrapper');

        var headerCarousalItems = document.querySelectorAll('.header__carousal .item');
        var headerCarousalItemsLength = headerCarousalItems?.length;

        // function for Generate Dynamic Mobile Memu
        function generateMobileMenu() {
            // div for inserting mobile menu
            var sideMenuWrap = document.querySelector('.side__menu-wrap .show__mobile');

            // Arrary for adding Menu items
            var mobileMenuArray = [];

            // function for Add Objcet
            const addLinkTitleObject = menuItem => {
                return {
                    link: menuItem?.href?.trim(),
                    title: menuItem?.textContent?.trim(),
                    isDataDynamic: dynamicDataList.find(e => e.title === menuItem?.textContent?.trim()) || ''
                };
            };
            const createMenu = menuItems => {
                return [...menuItems]?.map(menuItem => {
                    var menuLink = menuItem?.children?.[0];
                    var subMenuItems = menuItem?.children?.[1]?.children;
                    var subMenu = subMenuItems?.length > 0 ? createMenu(subMenuItems) : null;
                    return {
                        ...addLinkTitleObject(menuLink),
                        children: subMenu
                    };
                });
            };
            const createMobileMenu = (mainMenuItems, element) => {
                mainMenuItems.forEach(mainMenuItem => {
                    var mainMenuLink = mainMenuItem?.children[0];
                    var subMenuItems = mainMenuItem?.querySelectorAll(element);
                    var subMenu = subMenuItems?.length > 0 ? createMenu(subMenuItems) : null;
                    var mainMenuObject = {
                        ...addLinkTitleObject(mainMenuLink),
                        children: subMenu
                    };
                    mobileMenuArray.push(mainMenuObject);
                });
            };

            // function for check 'See All text'
            const checkForSeeAll = item => {
                return item?.title?.toLowerCase() === 'see all';
            };

            // function for check length
            const checkForLength = item => {
                return item?.children?.length > 0;
            };

            // function for back button
            const backButton = (buttonclass, item) => {
                return `<button class="${buttonclass}" aria-label="Click here to go back to ${item} menu ">
                <span class="side__menu-item--back-button icon icon-chevron-left-bold" tabindex='-1' aria-hidden="true"></span>
                <p class="back__to_text">${item}</p>
             </button>`;
            };

            //Li item for back
            const liBack = (liClass, backbuttonClass, item) => {
                return `<li class="${liClass}">${backButton(backbuttonClass, item)}</li>`;
            };

            const getOtherMenus = () => {
                const megaMenuWithoutEquipements = document.querySelectorAll('.header__menu .header__menu-topother');
                megaMenuWithoutEquipements.forEach(menuItem => {
                    var headerTopLink = menuItem?.querySelector('.header__top-link');
                    var headerL1MenuLinks = menuItem?.querySelectorAll('.header__l1__menu a');
                    var otherMenuObject = addLinkTitleObject(headerTopLink);
                    otherMenuObject['children'] = [];
                    [...headerL1MenuLinks]?.map(headerL1MenuLink => {
                        otherMenuObject['children'].push(addLinkTitleObject(headerL1MenuLink));
                    });
                    mobileMenuArray.push(otherMenuObject);
                });
            };
            const getEquipmentMenu = () => {
                const megaMenuWithEquipements = document?.querySelectorAll('.header__menu .header__menu-topmain');
                createMobileMenu(megaMenuWithEquipements, '.header__level1-item');
            };

            const createDynamicObject = () => {
                getEquipmentMenu();
                getOtherMenus();
            };
            createDynamicObject();
            const generateDynamicHTML = l1MenuItem => {
                return `<li class="side__menu-item side__menu-item-toplevel">
                <a href=${l1MenuItem?.link} class="side__menu-link all__rentals_text__title_mob">${
                    l1MenuItem?.title
                }</a>
                ${
                    checkForLength(l1MenuItem) || l1MenuItem?.isDataDynamic
                        ? `<button class="side__menu-item-button side__menu-button-1 icon icon-chevron-right-bold" aria-label="click for view ${l1MenuItem?.title} items" aria-expanded="false"></button>`
                        : ''
                }
               ${
                   checkForLength(l1MenuItem)
                       ? `<ul class="side__submenu">
                       ${liBack(
                           'side__menu-item side__menu-item-top side__menu-item-sublevel',
                           'back_main_menu',
                           l1MenuItem?.title
                       )}
                       ${l1MenuItem?.children
                           .map(l2MenuItem => {
                               return `<li class="side__menu-item side__menu-item-sublevel">
               <a class="side__menu-link all__rentals_text__l2_mob" href="${l2MenuItem.link}">${l2MenuItem.title}</a>
               ${
                   checkForLength(l2MenuItem)
                       ? `<button class="side__menu-item-button side__menu-button-2 icon icon-chevron-right-bold" aria-label="click for view ${l2MenuItem?.title} items" aria-expanded="false"></button>`
                       : ''
               }
               ${
                   checkForLength(l2MenuItem)
                       ? `<ul class="side__sub-submenu">

            ${liBack(
                'side__menu-item side__menu-item-top side__menu-item-sub-sublevel',
                'back_sub_menu',
                l2MenuItem?.title
            )}
            ${l2MenuItem?.children
                ?.map(
                    l3MenuItem => `
             <li class="${
                 checkForSeeAll(l3MenuItem) ? 'header__level2-item' : 'side__menu-item side__menu-item-sub-sublevel'
             }">
               <a href="${l3MenuItem.link}" class=" ${
                        checkForSeeAll(l3MenuItem)
                            ? 'header__submenu-link header__submenu__link header__submenu-seeall'
                            : 'side__menu-link all__rentals_text__l3_mob'
                    }">${l3MenuItem.title}</a>
               ${
                   checkForLength(l3MenuItem)
                       ? `<button class="side__menu-item-button side__menu-button-3 icon icon-chevron-right-bold" aria-label="click for view ${l3MenuItem?.title} items" aria-expanded="false"></button>`
                       : ''
               }
               ${
                   checkForLength(l3MenuItem)
                       ? `<ul class="side__sub-sub-submenu">
              
            ${liBack(
                'side__menu-item side__menu-item-top side__menu-item-sublevel side__menu-item-sub-sublevel',
                'back_sub_sub_menu',
                l3MenuItem?.title
            )}
            ${l3MenuItem?.children
                ?.map(
                    subsubmenu => `<li class="${
                        checkForSeeAll(subsubmenu)
                            ? ' header__level3-item'
                            : 'side__menu-item side__menu-item-sublevel side__menu-item-sub-sublevel'
                    }">
             <a class=" ${
                 checkForSeeAll(subsubmenu)
                     ? ' header__submenu-link header__submenu__link header__submenu-seeall'
                     : 'side__menu-link'
             }" href="${subsubmenu.link}">${subsubmenu.title}</a></li>`
                )
                .join('')
                .trim()}
               </ul>`
                       : ''
               }
               </li>`
                )
                .join('')
                .trim()}
               </ul>`
                       : ''
               }
               </li>
               `;
                           })
                           .join('')
                           .trim()}</ul>`
                       : l1MenuItem?.isDataDynamic
                       ? `<div class="${l1MenuItem?.isDataDynamic?.mountingClass} side__submenu"></div>`
                       : ''
               }
               
               </li> `;
            };

            mobileMenuArray?.map(item => {
                var ulcomponent = document?.createElement('ul');
                ulcomponent.innerHTML = generateDynamicHTML(item);
                sideMenuWrap?.appendChild(ulcomponent);
            });
        }
        // calling function for mobile menu generate
        generateMobileMenu();

        if (headerCarousalItems) {
            if (headerCarousalItemsLength === 1) {
                headerCarousal?.querySelector('.header__carousal')?.classList.add('header__carousal-hidebuttons');
            } else {
                headerCarousal?.querySelector('.header__carousal')?.classList.add('header__carousal-showbuttons');
            }
        }

        if (headerCarousal?.childElementCount === 0) {
            headerCarousal?.closest('.header__top')?.classList?.add('is-empty');
        }
        var trapFocus = element => {
            var focusableEls = element.querySelectorAll(
                'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), h1, h2, h3, h4, h5, h6, strong, b'
            );
            var firstFocusableEl = focusableEls[0];
            //firstFocusableEl.focus();
            var lastFocusableEl = focusableEls[focusableEls.length - 1];
            var KEYCODE_TAB = 9;
            element.addEventListener('keydown', function (e) {
                var isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
                if (!isTabPressed) {
                    return;
                }
                if (e.shiftKey) {
                    /* shift + tab */ if (document.activeElement === firstFocusableEl) {
                        lastFocusableEl.focus();
                        e.preventDefault();
                    }
                } /* tab */ else {
                    if (document.activeElement === lastFocusableEl) {
                        firstFocusableEl.focus();
                        e.preventDefault();
                    }
                }
            });
        };

        var setToggleState = false;
        var setAriaHiddenTrue = () => {
            const formContainerInput = document.querySelector('.formContainer .cmp-Field__field__input ');
            const searchIconClass = document.querySelector('.searchIconClass');
            const cartTrigger = document.querySelector('.cmp-CartTrigger__cartTrigger__root');
            const headerLocation = document.querySelector('.header__setlocation-button');
            if (formContainerInput) {
                formContainerInput.ariaHidden = 'true';
                formContainerInput.tabIndex = '-1';
            }
            if (searchIconClass) {
                searchIconClass.ariaHidden = 'true';
                searchIconClass.tabIndex = '-1';
            }
            if (cartTrigger) {
                cartTrigger.ariaHidden = 'true';
                cartTrigger.tabIndex = '-1';
            }
            if (headerLocation) {
                headerLocation.ariaHidden = 'true';
                headerLocation.tabIndex = '-1';
            }
        };
        var setAriaHiddenFalse = () => {
            const formContainerInput = document.querySelector('.formContainer .cmp-Field__field__input ');
            const searchIconClass = document.querySelector('.searchIconClass');
            const cartTrigger = document.querySelector('.cmp-CartTrigger__cartTrigger__root');
            const headerLocation = document.querySelector('.header__setlocation-button');
            if (formContainerInput) {
                formContainerInput.ariaHidden = 'false';
                formContainerInput.tabIndex = '0';
            }
            if (searchIconClass) {
                searchIconClass.ariaHidden = 'false';
                searchIconClass.tabIndex = '0';
            }
            if (cartTrigger) {
                cartTrigger.ariaHidden = 'false';
                cartTrigger.tabIndex = '0';
            }
            if (headerLocation) {
                headerLocation.ariaHidden = 'false';
                headerLocation.tabIndex = '0';
            }
        };

        var setTrapFocus = () => {
            setAriaHiddenTrue();
            trapFocus(sideMenu);
        };

        var handleSidebarToggle = function () {
            setToggleState = !setToggleState;
            sideMenu.classList.toggle('active');
            if (isP2PWithoutIframe()) {
                sideMenu.classList.toggle('add_top_margin');
            }
            sideMenuButton.classList.toggle('active');
            document.querySelector('body').classList.toggle('body--overflow-hidden');
            setToggleState ? setTrapFocus() : setAriaHiddenFalse();
        };

        headerSearchMobileBtn?.addEventListener('click', handleSidebarToggle);
        headerSearchClose?.addEventListener('click', handleSidebarToggle);
        sideMenuButton?.addEventListener('click', handleSidebarToggle);
        $('body').on('click', '.side__menu-button-1', function () {
            $(this)?.attr('aria-expanded', true);
            $(this)?.parent()?.addClass('active')?.siblings()?.removeClass('active');
            $(this)?.siblings()?.removeClass('active');
        });

        $('body').on('click', '.side__menu-button-2', function () {
            $(this)?.attr('aria-expanded', true);
            $(this)?.closest('.side__submenu')?.addClass('side__submenu-hidden');
            $(this)?.parent()?.addClass('active')?.siblings()?.removeClass('active');
            //$(this)?.siblings('.side__menu-link')?.attr('aria-expanded', 'true');
        });

        $('body').on('click', '.side__menu-button-3', function () {
            $(this)?.attr('aria-expanded', true);
            $(this)?.closest('.side__sub-submenu')?.addClass('side__submenu-hidden');
            $(this)?.parent()?.addClass('active')?.siblings()?.removeClass('active');
            //$(this)?.siblings('.side__menu-link')?.attr('aria-expanded', 'true');
        });
        $('body').on('click', '.back_sub_menu', function () {
            $(this)?.closest('.side__submenu')?.removeClass('side__submenu-hidden');
            $(this)?.parents('.side__menu-item-sublevel')?.removeClass('active');
            $(this)
                ?.parents('.side__menu-item-sublevel')
                ?.find('.side__menu-item-button.side__menu-button-2')
                ?.attr('aria-expanded', 'false');
        });

        $('body').on('click', '.back_sub_sub_menu', function () {
            $(this)?.closest('.side__sub-submenu')?.removeClass('side__submenu-hidden');
            $(this)?.parents('.side__menu-item-sub-sublevel')?.removeClass('active');
            $(this)
                ?.parents('.side__menu-item-sub-sublevel')
                ?.find('.side__menu-item-button.side__menu-button-3')
                ?.attr('aria-expanded', 'false');
        });

        $('body').on('click', '.back_main_menu', function () {
            $(this)?.parents('.side__menu-item-toplevel')?.removeClass('active');
            $(this)
                ?.parents('.side__menu-item-toplevel')
                ?.find('.side__menu-item-button.side__menu-button-1')
                ?.attr('aria-expanded', 'false');
        });

        $('body').on('click', '.back_main_menu_footer', function () {
            $(this)?.closest('.side__menu-item-toplevel_footer')?.removeClass('active');
            $(this)
                ?.closest('.side__menu-item-toplevel_footer')
                ?.find('.side__menu-item-button.side__menu-button-1')
                ?.attr('aria-expanded', 'false');
        });

        var headerMenuTopmain = $('.header__menu-topmain');
        const getScrollHeight = element => {
            return element?.scrollHeight;
        };
        const getOffsetHeight = element => {
            return element?.offsetHeight;
        };

        var slideIndex = 1;

        showSlides(slideIndex);
        if (
            document.querySelector('.header__carousal-prev') !== null &&
            document.querySelector('.header__carousal-prev') !== undefined
        ) {
            document.querySelector('.header__carousal-prev').addEventListener('click', function () {
                plusSlides(-1);
            });
        }
        if (
            document.querySelector('.header__carousal-next') !== null &&
            document.querySelector('.header__carousal-next') !== undefined
        ) {
            document.querySelector('.header__carousal-next').addEventListener('click', function () {
                plusSlides(1);
            });
        }
        function plusSlides(n) {
            showSlides((slideIndex += n));
        }
        function currentSlide(n) {
            showSlides((slideIndex = n));
        }
        function showSlides(n) {
            var i;
            var slides = document.getElementsByClassName('item');
            if (n > slides.length) {
                slideIndex = 1;
            }
            if (n < 1) {
                slideIndex = slides.length;
            }
            for (i = 0; i < slides.length; i++) {
                if (slides[i] !== null && slides[i] !== undefined) {
                    slides[i].style.display = 'none';
                }
            }
            if (slides[slideIndex - 1]) {
                slides[slideIndex - 1].style.display = 'block';
            }
        }

        var countTD = $('.spec-table tr:first > td').length;
        if (countTD === 2 || countTD === 3) {
            $('.spec-table tr td:last-child').after("<td class='td-empty'></td>.");
        }
        function createScroller(id, iconId) {
            const scrollContainer = document.querySelector(id);
            let offsetHeight = getOffsetHeight(scrollContainer);
            let scrollHeight = getScrollHeight(scrollContainer);
            const ITEM_HEIGHT = parseInt(scrollHeight / document.querySelector(id)?.childElementCount) * 2;
            //use id to create unique icon ids
            const icondown = document.querySelector(`.icondown${iconId}`);
            const iconup = document.querySelector(`.iconup${iconId}`);

            scrollContainer?.addEventListener('scroll', function (event) {
                var element = event.target;
                var diffenrece =
                    parseInt(element.scrollTop) + parseInt(element.offsetHeight) - parseInt(element.scrollHeight);
                if (diffenrece > -2 && diffenrece <= 2) {
                    icondown.classList.remove('show');
                    iconup.classList.add('show');
                }
                if (parseInt(element.scrollTop) <= 0) {
                    iconup.classList.remove('show');
                    icondown.classList.add('show');
                }
            });

            let offSetPrev = -1;
            if (scrollHeight > offsetHeight) {
                scrollContainer.classList.add('header__menu-large');
                icondown.classList.add('show');
            }
            const handleScrollUp = () => {
                let scrollTop = scrollContainer.scrollTop;

                scrollTop = parseInt(scrollTop) + parseInt(ITEM_HEIGHT);

                if (
                    parseInt(scrollContainer.scrollTop) + parseInt(scrollContainer.offsetHeight) !==
                    parseInt(scrollContainer.scrollHeight)
                ) {
                    offSetPrev = scrollTop;
                    scrollContainer.scrollBy(0, ITEM_HEIGHT);
                    var diffenrece =
                        parseInt(scrollContainer.scrollTop) +
                        parseInt(scrollContainer.offsetHeight) -
                        parseInt(scrollContainer.scrollHeight);
                    if (diffenrece > -2 && diffenrece <= 2) {
                        icondown.classList.remove('show');
                        iconup.classList.add('show');
                    }
                } else {
                    scrollContainer.scrollBy(0, -ITEM_HEIGHT);
                    icondown.classList.remove('show');
                    iconup.classList.add('show');
                }
            };

            const handleScrollDown = () => {
                let scrollTop = scrollContainer.scrollTop;
                scrollTop = scrollTop - ITEM_HEIGHT;
                offSetPrev = scrollTop;
                scrollContainer.scrollBy(0, -ITEM_HEIGHT);
                if (scrollTop < 0) {
                    iconup.classList.remove('show');
                    icondown.classList.add('show');
                }
            };
            icondown.addEventListener('click', handleScrollUp);
            iconup.addEventListener('click', handleScrollDown);
        }

        headerMenuTopmain.hover(function () {
            document.querySelector('.header__menu-level1').scrollTo(0, 0);
            createScroller('.header__menu-level1', 1);
        });

        const submenu1Item = $('.header__level1-item');

        submenu1Item.hover(function () {
            $(this).siblings().removeClass('active');
            $(this).addClass('active');
            $('.iconup2').removeClass('show');
            $('.icondown2').removeClass('show');
            $('.header__menu2').html('');
            $('.header__menu3').html('');
            $('.iconup3').removeClass('show');
            $('.icondown3').removeClass('show');
            $('.header__menu2').html($(this).find('.header__menu-level2').clone());
            $('.header__menu2').append(
                `
          <button class="header__submenu-action icondown2" aria-label="Click here to scroll down">
            <span class= "icon icon-chevron-down-thin" ></span>
            </button >
            <button class="header__submenu-action iconup2" aria-label="Click here to scroll up">
              <span class="icon icon-chevron-up-thin"></span>
            </button>
    `
            );
            createScroller('.header__menu2 > .header__menu-level2', 2);
            const headerMenuItem2 = $('.header__menu2 .header__level2-item');
            headerMenuItem2.hover(function () {
                $(this).siblings().removeClass('active');
                $(this).addClass('active');
                $('.iconup3').removeClass('show');
                $('.icondown3').removeClass('show');
                $('.header__menu3').html('');
                $('.header__menu3').html($(this).find('.header__menu-level3').clone());
                $('.header__menu3').append(
                    `
          <button class="header__submenu-action icondown3" aria-label="Click here to scroll down">
            <span class= "icon icon-chevron-down-thin" ></span>
            </button >
            <button class="header__submenu-action iconup3" aria-label="Click here to scroll up">
              <span class="icon icon-chevron-up-thin"></span>
            </button>
    `
                );
                createScroller('.header__menu3 > .header__menu-level3', 3);
            });
        });

        var isGTMHidden = document.querySelector('meta[name="hideGTM"]')?.content;
        if (isGTMHidden === 'false') {
            var navHeaders = document.querySelectorAll('.header__menu__link');
            navHeaders?.forEach(header_l1 => {
                header_l1?.addEventListener('click', function (e) {
                    var eventCategory = 'main menu L1';
                    var eventLabel = header_l1.innerText;
                    callGtm('uaevent', 'undefined', eventCategory, 'select', eventLabel);
                });
            });

            var navHeaders_l2 = document.querySelectorAll('.header__level1-item .header__submenu-link');
            navHeaders_l2?.forEach(header_l2 => {
                header_l2?.addEventListener('click', function (e) {
                    var eventCategory = 'main menu L2';
                    var eventLabel = e.target.innerText;
                    callGtm('uaevent', 'undefined', eventCategory, 'select', eventLabel);
                });
            });

            var navHeadersSub_l2 = document.querySelectorAll('.header__submenu__column__link');
            navHeadersSub_l2?.forEach(headerSub_l2 => {
                headerSub_l2?.addEventListener('click', function (e) {
                    var eventCategory = 'main menu L2';
                    var eventLabel = e.target.innerText;
                    callGtm('uaevent', 'undefined', eventCategory, 'select', eventLabel);
                });
            });

            var navHeaders_l3 = document.querySelectorAll('.header__menu2');
            navHeaders_l3?.forEach(header_l3 => {
                header_l3?.addEventListener('click', function (e) {
                    var eventCategory = 'main menu L3';
                    var eventLabel = e.target.innerText;
                    callGtm('uaevent', 'undefined', eventCategory, 'select', eventLabel);
                });
            });

            var navHeaders_l4 = document.querySelectorAll('.header__menu3');
            navHeaders_l4?.forEach(header_l4 => {
                header_l4?.addEventListener('click', function (e) {
                    var eventCategory = 'main menu L4';
                    var eventLabel = e.target.innerText;
                    callGtm('uaevent', 'undefined', eventCategory, 'select', eventLabel);
                });
            });
        }

        var resourceContainer = document.querySelectorAll('.accessibilitydropdown');
        if (resourceContainer) {
            resourceContainer?.forEach(function (item) {
                var dropdownBtn = item?.querySelector('button');
                if (dropdownBtn) {
                    item?.addEventListener('mouseover', () => {
                        dropdownBtn.ariaExpanded = 'true';
                    });

                    item?.addEventListener('focusin', event => {
                        dropdownBtn.ariaExpanded = 'true';
                    });

                    item?.addEventListener('focusout', event => {
                        dropdownBtn.ariaExpanded = 'false';
                    });

                    item?.addEventListener('mouseout', () => {
                        dropdownBtn.ariaExpanded = 'false';
                    });
                }
            });
        }
    }
});
