import { createLazyImports } from '../../global/lazyLoad/lazyLoadUtils';

const componentPaths = {
    DelinquentBanner: () =>
        import(
            /* webpackChunkName: "cmp-DelinquentBanner" */ '../../../aem-core-components/components/Banner/DelinquentBanner/DelinquentBanner'
        ),
    LocationDistanceWarningBanner: () =>
        import(
            /* webpackChunkName: "cmp-LocationDistanceWarningBanner" */ '../../../aem-core-components/components/Banner/LocationDistanceWarningBanner/LocationDistanceWarningBanner'
        ),
    UtilityBanner: () =>
        import(/* webpackChunkName: "cmp-UtilityBanner" */ '../../global/modules/utilityBanner/UtilityBanner'),
    MyFavourites: () => import(/* webpackChunkName: "cmp-MyFavourites" */ '../../myFavourites/MyFavourites'),
    PunchoutBanner: () =>
        import(/* webpackChunkName: "cmp-PunchoutBanner" */ '../../global/modules/punchoutBanner/PunchoutBanner'),
    GetGeoLocation: () =>
        import(/* webpackChunkName: "cmp-GetGeoLocation" */ '../../global/atoms/GetGeoLocation/GetGeoLocation'),
    GlobalAccountHeader: () =>
        import(/* webpackChunkName: "cmp-GlobalAccountHeader" */ '../../account/GlobalAccountHeader'),
    GlobalSearch: () => import(/* webpackChunkName: "cmp-GlobalSearch" */ '../../search/globalSearch/GlobalSearch')
};

const LazyLoadGlobalImports = createLazyImports(componentPaths);

export const {
    DelinquentBanner,
    LocationDistanceWarningBanner,
    UtilityBanner,
    MyFavourites,
    PunchoutBanner,
    GetGeoLocation,
    GlobalAccountHeader,
    GlobalSearch
} = LazyLoadGlobalImports;