import { createLazyImports } from '../../global/lazyLoad/lazyLoadUtils';

const componentPaths = {
    RentalsCombineTab: () =>
        import(
            /* webpackChunkName: "cmp-RentalsCombineTab" */ '../../global/modules/rentalsCombineTab/RentalsCombineTab'
        ),
    LdpAlert: () => import(/* webpackChunkName: "cmp-LdpAlert" */ '../../ldpAlert/LdpAlert'),
    CapBottomSticky: () => import(/* webpackChunkName: "cmp-CapBottomSticky" */ '../../cap/capBottomSticky/CapBottom'),
    DataBricks: () => import(/* webpackChunkName: "cmp-DataBricks" */ '../../global/modules/dataBricks/DataBricks')
};

const LazyLoadAppImports = createLazyImports(componentPaths);

export const { RentalsCombineTab, LdpAlert, CapBottomSticky, DataBricks } = LazyLoadAppImports;