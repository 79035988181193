import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import SentryFallback from '../../App/SentryFallback';

const sentryFallback = ({ error, componentStack, resetError }) => (
    <SentryFallback error={error} componentStack={componentStack} resetError={resetError} />
);

const LazyLoadComponent = ({ fallback, children }) => {
    return (
        <Sentry.ErrorBoundary fallback={sentryFallback}>
            <Suspense fallback={fallback || <></>}>{children}</Suspense>
        </Sentry.ErrorBoundary>
    );
};

export default LazyLoadComponent;