import React, { memo } from 'react';
import { bool } from 'prop-types';
import { CartTrigger, Portal } from '../../aem-core-components';
import MultiPortal from '../../aem-core-components/components/Portal/MultiPortal';
import {
    DelinquentBanner,
    LocationDistanceWarningBanner,
    UtilityBanner,
    MyFavourites,
    PunchoutBanner,
    GetGeoLocation,
    GlobalAccountHeader,
    GlobalSearch
} from './lazyLoad/LazyLoadGlobalImports';
import LazyLoadComponent from '../global/lazyLoad/LazyLoadComponent';
import { useCheckAuthorityType } from '../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../global/constants';
import partialConfig from './config';
import { useGlobal } from './hooks/useGlobal';

const { mountingPoints } = partialConfig;

const Global = props => {
    useGlobal();
    const authorityType = useCheckAuthorityType();
    const isP2P = authorityType === AUTHORITY_TYPE.P2P;

    return (
        <>
            <LazyLoadComponent fallback={<></>}>
                <Portal selector={mountingPoints.search}>
                    <GlobalSearch />
                </Portal>
                <Portal selector={mountingPoints.cartTrigger}>
                    <CartTrigger />
                </Portal>
                <Portal selector={mountingPoints.pdpSelectLocation}>
                    <GlobalAccountHeader isCCHeader={props?.isCCHeader} />
                </Portal>
                {isP2P && (
                    <Portal selector={mountingPoints.punchoutBanner}>
                        <PunchoutBanner />
                    </Portal>
                )}
                <Portal selector={mountingPoints.getUtilityBanner}>
                    <UtilityBanner />
                </Portal>
                <Portal selector={mountingPoints.getGeoLoation}>
                    <GetGeoLocation />
                </Portal>
                <Portal selector={mountingPoints.notificationBanner}>
                    <DelinquentBanner />
                    <LocationDistanceWarningBanner />
                </Portal>
                {isP2P && (
                    <MultiPortal selector={mountingPoints.favouritesDropDown}>
                        <MyFavourites />
                    </MultiPortal>
                )}
            </LazyLoadComponent>
        </>
    );
};

Global.propTypes = {
    isCCHeader: bool
};

Global.defaultProps = {
    isCCHeader: false
};

export default memo(Global);
