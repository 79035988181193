import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';
import { App as CommerceApp, ConfigContextProvider, Portal } from '../../aem-core-components';
import MultiPortal from '../../aem-core-components/components/Portal/MultiPortal';
import { RentalsCombineTab, LdpAlert, CapBottomSticky, DataBricks } from './lazyLoad/LazyLoadAppImports';
import LazyLoadComponent from '../global/lazyLoad/LazyLoadComponent';
import Wires from '../global/atoms/wires/Wires';
import { initSentry } from '../global/utils/logger';
import ContactUsMarketo from '../marketoforms/contactUs';
import AddToCartProductTile from '../global/modules/productTile/addToCartProductTile';
import { CapHeader, CapSignIn } from '../cap';
import { usePageType } from '../../hooks/usePageType';
import partialConfig from './config';
import Global from './Global';
import HeroSearch from '../search/heroSearch';
import loadLocaleData, { locale, messages } from './i18n';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables';

const App = props => {
    const {
        storeView,
        graphqlEndpoint,
        graphqlMethod = 'POST',
        headers = '{}'
    } = JSON.parse(
        document.querySelector('meta[name="store-config"]')?.content ||
            document.querySelector('div[name="store-config"]')?.getAttribute('content') ||
            '{}'
    );

    const { mountingPoints, pagePaths } = partialConfig;
    const config = {
        ...partialConfig,
        storeView,
        graphqlEndpoint,
        graphqlMethod,
        headers
    };
    const pageType = usePageType();
    
    return (
        <IntlProvider locale={locale} messages={messages}>
            <ConfigContextProvider config={config}>
                <CommerceApp>
                    <Global />
                    <Portal selector={mountingPoints.heroSearchBox}>
                            <HeroSearch />
                    </Portal>
                    <Portal selector={mountingPoints.contactUsMarketo}>
                            <ContactUsMarketo />
                    </Portal>
                    <MultiPortal selector={mountingPoints.addToCartTile}>
                            <AddToCartProductTile />
                    </MultiPortal>
                    <Portal selector={mountingPoints.combinedTabs}>
                        <LazyLoadComponent fallback={<></>}>
                            <RentalsCombineTab />
                        </LazyLoadComponent>
                    </Portal>
                    <Portal selector={mountingPoints.wiresCrossed}>
                            <Wires />
                    </Portal>
                    <Portal selector={mountingPoints.capSideHeader}>
                            <CapSignIn />
                    </Portal>
                    <Portal selector={mountingPoints.capHeader}>
                            <CapHeader />
                    </Portal>
                    <Portal selector={mountingPoints.capHeaderBottomSticky}>
                        <LazyLoadComponent fallback={<></>}>
                            <CapBottomSticky />
                        </LazyLoadComponent>
                    </Portal>
                    {pageType === VARIABLE_CONFIG.PAGE_TYPE.LOCATION_PAGE && (
                        <Portal selector={mountingPoints.ldpAlertMessage}>
                            <LazyLoadComponent fallback={<></>}>
                                <LdpAlert />
                            </LazyLoadComponent>
                        </Portal>
                    )}
                    <Portal selector={mountingPoints.recommendationCarousal}>
                        <LazyLoadComponent fallback={<></>}>
                            <DataBricks />
                        </LazyLoadComponent>
                    </Portal>
                </CommerceApp>
            </ConfigContextProvider>
        </IntlProvider>
    );
};

const renderDOM = async () => {
    const { locale, messages } = await loadLocaleData();
    const root = document.createElement('div');
    document.body.appendChild(root);
    const isDevelopment = process.env.NODE_ENV === 'development';

    if (!isDevelopment) {
        initSentry();
    }

    const rootContainer = createRoot(root);
    rootContainer.render(
        <Router>
            <App locale={locale} messages={messages} />
        </Router>
    );
};

document?.addEventListener('readystatechange', () => {
    if (document?.readyState === 'complete') {
        renderDOM();
    }
});

export default App;