import React, { memo, useEffect, useRef } from 'react';
import { bool, element, func, oneOf, oneOfType, string } from 'prop-types';
import { useIntl } from 'react-intl';
import ReactModal from 'react-modal';
import Close from '../../../../resources/images/close.svg';
import Button from '../button/button';
import './globalModal.scss';

const GlobalModal = props => {
    const {
        isOpen,
        onRequestClose,
        title,
        content,
        footer,
        handleClose,
        overlayClassName,
        className,
        portalClassName,
        closeClassName,
        titleClasses,
        footerClasses,
        contentClasses,
        sideDrawer,
        showCloseButton,
        settitleFocus,
        customTitle,
        handleOnAfterOpen,
        closeButtonAriaLabel
    } = props;
    const intl = useIntl();

    const sideDrawerOverlayClasses = {
        left: 'globalmodal__overlay--slide-left',
        right: 'globalmodal__overlay--slide-right'
    };
    const sideDrawerContentClasses = {
        left: 'globalmodal__content--slide-left',
        right: 'globalmodal__content--slide-right'
    };

    useEffect(() =>{
        if(isOpen){
            ReactModal.setAppElement('.root');
        }
        return () => {
            document.body.classList.remove('ReactModal__Body--open');
        }
    },[isOpen])

    return (
        <ReactModal
            role="none"
            isOpen={isOpen}
            overlayClassName={`globalmodal__overlay ${sideDrawerOverlayClasses[sideDrawer]} ${overlayClassName}`}
            onRequestClose={onRequestClose}
            portalClassName={portalClassName}
            className={`globalmodal__content ${sideDrawerContentClasses[sideDrawer]} ${className}`}
            onAfterOpen={handleOnAfterOpen}
            ariaHideApp={false}
            >
            {customTitle}
            {title && (
                <h6 className={`globalmodal__title ${titleClasses}`} tabIndex={'0'} data-testid={'globalmodal-title'}>
                    {title}
                </h6>
            )}
            {content && <div className={`globalmodal__content-wrap ${contentClasses}`}>{content}</div>}
            {footer && <div className={`globalmodal__footer ${footerClasses}`}>{footer}</div>}
            {showCloseButton && (
                <Button
                    dataTestid={'legend-modal-close'}
                    onClick={handleClose}
                    className={`globalmodal__close button button-pb0 ${closeClassName}`}
                    buttonAriaLabel={closeButtonAriaLabel || intl.formatMessage({ id: 'llp-modal-close' })}
                    tabIndex={'0'}>
                    <Close tabIndex={'-1'} aria-hidden={true} />
                </Button>
            )}
        </ReactModal>
    );
};

GlobalModal.defaultProps = {
    title: '',
    content: '',
    footer: '',
    isOpen: false,
    onRequestClose: () => {},
    handleClose: () => {},
    overlayClassName: '',
    className: '',
    closeClassName: '',
    footerClasses: '',
    titleClasses: '',
    sideDrawer: '',
    showCloseButton: true,
    settitleFocus: true,
    contentClasses: '',
    customTitle: '',
    handleOnAfterOpen: () => {}
};

GlobalModal.prototype = {
    title: oneOfType([string, element]),
    content: oneOfType([string, element]),
    footer: oneOfType([string, element]),
    onRequestClose: func,
    handleClose: func,
    isOpen: bool,
    overlayClassName: string,
    className: string,
    closeClassName: string,
    footerClasses: string,
    titleClasses: string,
    contentClasses: string,
    sideDrawer: oneOf(['left', 'right']),
    showCloseButton: bool,
    settitleFocus: bool,
    customTitle: string,
    handleOnAfterOpen: func
};

export default memo(GlobalModal);
