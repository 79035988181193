export const TILE_STATES = {
    AVAILABLE: 'AVAILABLE',
    CHANGE_STORE: 'CHANGE_STORE',
    CHANGE_DATES_GRT24HRS: 'CHANGE_DATES_GRT24HRS',
    CHANGE_DATES_GRT72HRS: 'CHANGE_DATES_GRT72HRS',
    CHANGE_DATES_OR_STORE_GRT72HRS: 'CHANGE_DATES_OR_STORE_GRT72HRS',
    CHANGE_DATES_OR_STORE_GRT24HRS: 'CHANGE_DATES_OR_STORE_GRT24HRS',
    EMPTY_LOCATION: 'EMPTY_LOCATION',
    UNAVAILABLE: 'UNAVAILABLE',
    EMPTY_DATES: 'EMPTY_DATES',
    EMPTY_LOCATION_AND_EMPTY_DATES: 'EMPTY_LOCATION_AND_EMPTY_DATES',
    CHANGE_DATES_AND_STORE_GRT72HRS: 'CHANGE_DATES_AND_STORE_GRT72HRS',
    CHANGE_DATES_AND_STORE_GRT24HRS: 'CHANGE_DATES_AND_STORE_GRT24HRS',
    AVAILABLE_WITH_WARNING: 'AVAILABLE_WITH_WARNING',
    RAQ: 'RAQ'
};

export const P2P = 'P2P';
export const DOTCOM = 'DOTCOM';
export const REQUEST_QUOTE_CONSTANT = {
    quotationdescription: 'Please tell us about your project needs and we will contact you promptly.',
    quotationheading: 'Request a Quote'
};

export const IMAGE_HEIGHT = '156';