import { createLazyImports } from '../../global/lazyLoad/lazyLoadUtils';

const componentPaths = {
    CapHero: () => import(/* webpackChunkName: 'cmp-capHero' */ '../capHero/CapHero'),
    LocationCapHeader: () =>
        import(/* webpackChunkName: 'cmp-locationCapHeader' */ '../locationCapHeader/LocationCapHeader'),
    PersistantCapHeader: () =>
        import(/* webpackChunkName: 'cmp-persistantCapHeader' */ '../persistantCapHeader/PersistantHeader'),
    RentalLocation: () => import(/* webpackChunkName: 'cmp-rentalLocation' */ '../rentalLocation/RentalLocation'),
    CapDatePicker: () => import(/* webpackChunkName: 'cmp-capDatePicker' */ '../capDatePicker/CapDatePicker'),
    FulfillmentStatus: () =>
        import(/* webpackChunkName: 'cmp-fulfillmentStatus' */ '../fulfillmentStatus/FulfillmentStatus'),
    DrawerRentalLocation: () =>
        import(/* webpackChunkName: 'cmp-drawerRentalLocation' */ '../drawerRentalLocation/DrawerRentalLocation'),
    RentalCoachmark: () => import(/* webpackChunkName: 'cmp-rentalCoachmark' */ '../rentalCoachmark/RentalCoachmark'),
    RentalChangeConfirmationModal: () =>
        import(
            /* webpackChunkName: 'cmp-rentalChangeConfirmationModal' */ '../../global/modules/rentalChangeConfirmationModal/RentalChangeConfirmationModal'
        )
};

const LazyLoadCapImports = createLazyImports(componentPaths);

export const {
    CapHero,
    LocationCapHeader,
    PersistantCapHeader,
    RentalLocation,
    CapDatePicker,
    FulfillmentStatus,
    DrawerRentalLocation,
    RentalCoachmark,
    RentalChangeConfirmationModal
} = LazyLoadCapImports;