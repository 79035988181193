const applicationStatusApprovedDataSet = document.querySelector('.cca_application_status_approved')?.dataset;
const applicationStatusReviewDataSet = document.querySelector('.cca_application_status_review')?.dataset;
const applicationStatusLoadingDataSet = document.querySelector('.cca_application_status_processing')?.dataset;
const applicationStatusErrorDataSet = document.querySelector('.cca_application_status_error')?.dataset;
const grApplicationStatusErrorDataSet = document.querySelector('.gr_application_status_error')?.dataset;
const ccaPageUrls = document.querySelector('.cca__pageUrls')?.dataset;

export const ENV_CONFIG = {
    PDP_SELECT_LOCATION: (() => document.querySelector('.pdp__select__location')?.dataset || {})(),
    HTML_DATASET: (() => document.querySelector('html').dataset)(),
    STORE_URL: (() => document.querySelector('body')?.dataset?.storeRootUrl)(),
    DUKEC_ENDPOINT: (() => document.querySelector('meta[data-endpoint-key="DukeC"]')?.dataset?.endpointValue)(),
    MAX_ACCESSORIES: (() => document.querySelector('.checkout')?.dataset?.maxaccessories || 3)(),
    MAX_QUANTITY_LIMIT: (() => document.querySelector('.checkout')?.dataset?.maxquantitylimit || 25)(),
    PHONE_NUMBER: (() => document.querySelector('.checkout')?.dataset?.phonenumber || '8006679328')(),
    MAXADDONS: (() => document.querySelector('.checkout')?.dataset?.maxaddons || 3)(),
    MAXADDONSQUANTITY: (() => document.querySelector('.checkout')?.dataset?.maxaddonsquantity || 25)(),
    TEMPUSURL: (() => document.querySelector('meta[name="generic-variables"]')?.dataset?.tempusUrl)(),
    PDP_HERO: (() => document.querySelector('.block--pdp-hero')?.dataset)(),
    JOBSITESLIMIT: (() => document.querySelector('meta[data-endpoint-key="jobSitesLimit"]')?.dataset?.endpointValue)(),
    PRODUCTDATA: (() => document.querySelector('.combined_tabs')?.dataset?.productdata)(),
    FRONTEND_VARIABLES: (() => document.querySelector('div[name="frontend-variables"]')?.dataset)(),
    ENV: (() => document.querySelector('meta[data-endpoint-key="Env"]')?.dataset?.endpointValue)(),
    ENDPOINT: (() => document.querySelector('meta[data-endpoint-key="Endpoint"]')?.dataset?.endpointValue)(),
    SUPPORTTEXT: (() => document.querySelector('.storeLocationsConfig')?.dataset?.supportText)(),
    RADIUS: (() => document.querySelector('.storeLocationsConfig')?.dataset?.radius)(),
    NEARESTPC: (() => document.querySelector('.storeLocationsConfig')?.dataset?.nearestPc)(),
    REQUEST_QUOTE: (() => document.querySelector('.request-quote')?.dataset || {})(),
    SELECT_LOCATION: (() => document.querySelector('.select__location')?.dataset || {})(),
    GENERIC_VARIABLES_META: (() => document.querySelector('meta[name="generic-variables"]')?.dataset)(),
    GENERIC_VARIABLES_DIV: (() => document.querySelector('div[name="generic-variables"]')?.dataset)(),
    SHOWMORE: (() => document.querySelector('.popular__rentals')?.dataset?.showmore)(),
    SEARCH_COVEO: (() =>
        document.querySelector('.search__coveo')?.dataset || {
            productslabel: 'Products',
            productscount: 4,
            categorieslabel: 'Categories',
            categoriescount: 3,
            searchtermslabel: 'Search Terms',
            searchtermscount: 3,
            relatedinfolabel: 'Related Information',
            relatedinfocount: 3,
            searchplaceholderlabel: 'What are you looking for?'
        })(),
    HERO_SEARCH_BOX: (() =>  document.querySelector('.herosearch__box')?.dataset || {
        productslabel: 'Products',
        productscount: 4,
        categorieslabel: 'Categories',
        categoriescount: 3,
        searchtermslabel: 'Search Terms',
        searchtermscount: 3,
        relatedinfolabel: 'Related Information',
        relatedinfocount: 3,
        searchplaceholderlabel: 'Search for rental equipment?',
        searchctalabel: 'Search'
    } )(),
    HIDE_CONTENT: (() => document.querySelector('meta[name="hideGTM"]')?.content || true)(),
    GTM_MODE: (() => document.querySelector('meta[data-endpoint-key="GTMMode"]')?.dataset?.endpointValue)(),
    META_FACETS_STATE: (() =>
        document.querySelector("meta[name='facetsDefaultViewState']")?.content.toLowerCase() === 'expanded' || false)(),
    STATE_LIST: (() =>
        document.querySelector('meta[data-endpint-key="statelist"]')?.dataset?.endpointValue ||
        `Alberta,AB,British Columbia,BC,Manitoba,MB,Ontario,ON,Saskatchewan,SK,Nova Scotia,NS,New Brunswick,NB`)(),
    IP_ADDRESS_DEFAULT: (() =>
        document.querySelector('meta[name="ipAddress"]')?.dataset?.content || '123.123.123.123')(),
    LOADER_DELAY: (() =>
        parseInt(document.querySelector('meta[data-endpoint-key="loader-delay"]')?.dataset?.endpointValue) || 1000)(),
    HELP_DESK_PHONE: (() =>
        document.querySelector('meta[data-endpoint-key="helpdesk-phone"]')?.dataset?.endpointValue || '800-508-4756')(),
    HELP_DESK_EMAIL: (() =>
        document.querySelector('meta[data-endpoint-key="helpdesk-email"]')?.dataset?.endpointValue ||
        'arcustsupport@sunbeltrentals.com')(),
    SPECIALITY_TYPE_CONFIG: (() => document.getElementsByName('speciality-types-config') || '')(),
    EXCLUDED_SPECIALTY_LIST: (() =>
        document.querySelector('.exclude_speciality_rates')?.dataset?.excludespecialityrates || '')(),
    PICKUP_STORE_EXCLUDED_SPECIALTY_LIST: (() =>
        document.querySelector('.exclude_speciality_stores')?.dataset?.excludespecialitystores || '')(),
    NOTIFICATION_BANNER_TITLE: (() => document.querySelector('.alert__banner')?.dataset?.title || '')(),
    NOTIFICATION_BANNER_INACTIVE_DESCRIPTION: (() =>
        document.querySelector('.alert__banner')?.dataset?.descriptionblocked || '')(),
    NOTIFICATION_BANNER_PHONE: (() => document.querySelector('.alert__banner')?.dataset?.phonenumber || '')(),
    COMBINED_TABS: (() => document.querySelector('.combined_tabs'))(),
    SHOW_AEM_ACC_SELECTOR_ON_CC: (() =>
        JSON.parse(
            document.querySelector('meta[data-endpoint-key="showAemAccSelectorOnCC"]')?.dataset?.endpointValue ||
            document
                .querySelector('div[data-endpoint-key="showAemAccSelectorOnCC"]')
                ?.getAttribute('data-endpoint-value') ||
            false
        ))(),
    FRONTEND_CONFIG: (() =>
        document.querySelector('meta[name="frontend-variables"]')?.dataset ||
        document.querySelector('div[name="frontend-variables"]')?.dataset)(),
    ENVIRONMENT: (() => document.querySelector('meta[data-endpoint-key="Env"]')?.dataset?.endpointValue)(),
    GOOGLE_API_KEY: (() =>
        document.querySelector('meta[data-endpoint-key="googleApiKey"]')?.dataset?.endpointValue ||
        'AIzaSyBiyfHzyczl2hmeggn5qT_DzBMBBG7rSxs')(),
    VALID_STATE_LIST: (() =>
        document.querySelector('meta[data-endpoint-key="stateslist"]')?.dataset?.endpointValue ||
        'Alberta,AB,British Columbia,BC,Manitoba,MB,Ontario,ON,Saskatchewan,SK,Nova Scotia,NS,New Brunswick,NB')(),
    IS_EDITOR_MODE:
        (() => {
            const element = document.querySelector('div.checkout_editor_setup');
            return element ? element.dataset.iseditor === 'true' : false;
        })() ?? false,
    CheckoutImageSrc: (() =>
        document.querySelector('.checkout')?.dataset?.src ||
        'https://media.sunbeltrentals.com/is/image/sunbeltrentals/SBR_checkout_102022_image?$Checkout-L$')(),
    CheckoutImageSrcset: (() =>
        document.querySelector('.checkout')?.dataset?.srcset ||
        ' https://media.sunbeltrentals.com/is/image/sunbeltrentals/SBR_checkout_102022_image?$Checkout-S$ 640w,https://media.sunbeltrentals.com/is/image/sunbeltrentals/SBR_checkout_102022_image?$Checkout-M$ 1024w,https://media.sunbeltrentals.com/is/image/sunbeltrentals/SBR_checkout_102022_image?$Checkout-L$ 1200w')(),
    CheckoutImageAlt: (() => document.querySelector('.checkout')?.dataset?.alttext || 'Checkout Hero')(),
    locationUSplaceholderText: (() => document.querySelector('.location_landing_page')?.dataset?.usplaceholdertext)(),
    locationCAplaceholderText: (() => document.querySelector('.location_landing_page')?.dataset?.caplaceholdertext)(),
    locationSearchBoxTitle: (() => document.querySelector('.location_landing_page')?.dataset?.searchboxtitle)(),
    LOCATION_LANDING_PAGE_CONFIGS: (() => document.querySelector('.location_landing_page')?.dataset)(),
    DEFAULT_AUTOSUGGEST_RADIUS: (() =>
        document.querySelector('.location_landing_page')?.dataset?.defaultsearchradius || 100)(),
    DEFAULT_AUTOSUGGEST_LAT: (() =>
        document.querySelector('.location_landing_page')?.dataset?.defaultlocationlatitude || 35.030271)(),
    DEFAULT_AUTOSUGGEST_LONG: (() =>
        document.querySelector('.location_landing_page')?.dataset?.defaultlocationlongitude || -80.961383)(),
    INVENTORY_CHECK_CONFIGS: (() => document.querySelector('#inventoryCheckConfigs')?.dataset)(),
    POPULAR_CATEGORY_CONTAINER: (() => document.querySelector('#popular__categories__search'))(),
    PLP_SEARCH_PAGE_CONTAINER: (() => document.querySelector('.plp__search__page'))(),
    BREADCRUMB_LIST_CONTAINER: (() => document.querySelector('.breadcrumb__list'))(),
    BREADCRUMB_ACTIVE_LINK: (() => document.querySelector('.breadcrumb__item.breadcrumb__item--active a'))(),
    PlpSearchContainer: (() => document.querySelector('.plp__search__page'))(),
    SRPSearchContainer: (() => document.querySelector('.search__results__page'))(),
    SRPShimmerContainer: (() => document.querySelector('.search-shimmer:not(.suspense-fallback)'))(),
    SRPSeasonalCarousel: (() => document.querySelectorAll('.seasonal__carousel'))(),
    PAGE_TEMPLATE_STRING: (() => document.querySelector('meta[name="template"]')?.content)(),
    SUPPORT_CONTACT_NUMBER: (() => document.querySelector('.footer__top-link')?.innerHTML || document.querySelector('.footer__top-action .location__call')?.innerHTML)(),
    LLP_INITIAL_RADIUS: (() => document.querySelector('.storeLocationsConfig')?.dataset?.radius || 200)(),
    HOLIDAY_LIST_US: (() => document.querySelector('#holidays-us')?.dataset?.holidaylist || '')(),
    HOLIDAY_LIST_CA: (() => document.querySelector('#holidays-ca')?.dataset?.holidaylist || '')(),
    SPECIALITY_TYPE_URL_CONFIG: (() => document.getElementsByName('speciality-urlformats-config') || '')(),
    DST_SEARCH_TITLE: (() =>
        document.querySelector('.dst_trainer_page')?.dataset?.searchtitle || 'Find a Safety Trainer')(),
    DST_SEARCH_PLACEHOLDER: (() =>
        document.querySelector('.dst_trainer_page')?.dataset?.searchplaceholder || 'ZIP, Postal Code or Address')(),
    DST_TAG_NAMES: (() =>
        document.querySelector('.dst_trainer_page')?.dataset?.tagnames?.length > 2
            ? document.querySelector('.dst_trainer_page')?.dataset?.tagnames
            : '[sunbelt-rentals:training-set/bi-lingual-spanish=Bi-Lingual Spanish, sunbelt-rentals:training-set/ttt=TTT, sunbelt-rentals:training-set/forklift=Forklift, sunbelt-rentals:training-set/mewp=Mewp, sunbelt-rentals:training-set/earth-moving=Earth Moving, sunbelt-rentals:training-set/fall-protection=Fall Protection, sunbelt-rentals:training-set/spotter-training=Spotter Training, sunbelt-rentals:training-set/carts--(polaris-atv)=Carts? (Polaris ATV), sunbelt-rentals:training-set/scaffolding-(supported)=Scaffolding (supported), sunbelt-rentals:training-set/scaffolding-(suspended)=Scaffolding (suspended), sunbelt-rentals:training-set/shoring-trenching=Shoring/trenching, sunbelt-rentals:training-set/confined-space-(canada-only)=Confined Space (CANADA ONLY), sunbelt-rentals:training-set/rigging-&-signaling=Rigging & Signaling, sunbelt-rentals:training-set/propane=Propane, sunbelt-rentals:training-set/propane-and-nat-gas=Propane and Nat Gas, sunbelt-rentals:training-set/propane-in-roofing=Propane In Roofing, sunbelt-rentals:training-set/propane-filling=Propane Filling, sunbelt-rentals:training-set/chainsaw=Chainsaw, sunbelt-rentals:training-set/hoisting-and-rigging=Hoisting And Rigging, sunbelt-rentals:training-set/lock-out-tag-out=Lock out tag out, sunbelt-rentals:training-set/whmis=WHMIS]')(),
    DST_NEED_HELP_CONTACT: (() =>
        document.querySelector('.dst_trainer_page')?.dataset?.callusphonenumber || '1-888-846-1078')(),
    DST_NO_STORE_CONTACT: (() =>
        document.querySelector('.dst_trainer_page')?.dataset?.nonearbyphonenumber || '800-667-9328')(),
    DST_CALL_US_MESSAGE: (() =>
        document.querySelector('.dst_trainer_page')?.dataset?.callusmessage || 'Need help right away? Call us at')(),
    DST_NO_TRAINERS_TITLE: (() =>
        document.querySelector('.dst_trainer_page')?.dataset?.notrainerstitle ||
        'There are no trainers near this address')(),
    DST_NO_TRAINERS_BODY_1: (() =>
        document.querySelector('.dst_trainer_page')?.dataset?.notrainerslineone ||
        'Don’t worry, we have other training options available.')(),
    DST_NO_TRAINERS_BODY_2: (() =>
        document.querySelector('.dst_trainer_page')?.dataset?.notrainerslinetwo ||
        'Call us at #nonearbyphonenumber and we’ll find the resources you need.')(),
    DST_FILTER_LABEL: (() =>
        document.querySelector('.dst_trainer_page')?.dataset?.filterlabel || 'Core training set')(),
    FRONTEND_VARIABLESMETA: (() => document.querySelector('meta[name="frontend-variables"]')?.dataset)(),
    RATE_CONFIGS: (() => document.querySelector('#rateConfigs')?.dataset)(),
    SPECIALTY_TYPE: (() => document?.querySelector('.locationdetail__title')?.textContent)(),
    RATES_MESSAGES: (() => document?.querySelector('.rate-messages-config')?.dataset?.ratemessages)(),
    LLP_LEGEND_SPECIALTY_STORE_TITLE: (() =>
        document.querySelector('.location_landing_page')?.dataset?.specialtystoretitle || 'Specialty')(),
    LLP_LEGEND_GT_STORE_TITLE: (() =>
        document.querySelector('.location_landing_page')?.dataset?.defaultstoretitle || 'General Tool & Equipment')(),
    LLP_LEGEND_TITLE: (() =>
        document.querySelector('.location_landing_page')?.dataset?.legendtitle || 'Location type')(),
    COMMERCIAL_CREDIT_LABEL: (() => document.querySelector('.cca_workflow_header')?.dataset?.commercialcreditlabel)(),
    COMMERCIAL_CREDIT_EXIT_LABEL: (() => document.querySelector('.cca_workflow_header')?.dataset?.exitlabel)(),
    COMMERCIAL_CREDIT_HELP_LABEL: (() => document.querySelector('.cca_workflow_header')?.dataset?.helplabel)(),
    COMMERCIAL_CREDIT_HELP_IMAGE: (() => document.querySelector('.cca_workflow_header')?.dataset?.helpiconimagesrc)(),
    COMMERCIAL_CREDIT_EXIT_IMAGE: (() => document.querySelector('.cca_workflow_header')?.dataset?.exiticonimagesrc)(),
    COMMERCIAL_CREDIT_BACK_IMAGE: (() => document.querySelector('.cca_workflow_header')?.dataset?.backiconimagesrc)(),
    COMMERCIAL_CREDIT_BACK_ARIA_LABEL: (() =>
        document.querySelector('.cca_workflow_header')?.dataset?.backbuttonarialabel)(),
    COMMERCIAL_CREDIT_HELP_ARIA_LABEL: (() => document.querySelector('.cca_workflow_header')?.dataset?.helparialabel)(),
    COMMERCIAL_CREDIT_EXIT_ARIA_LABEL: (() => document.querySelector('.cca_workflow_header')?.dataset?.exitarialabel)(),

    HELP_SUPPORT_MODAL_TITLE: (() =>
        document.querySelector('.cca_workflow_header')?.dataset?.helpandsupportmodaltitle)(),
    HELP_SUPPORT_MODAL_TEXT: (() => document.querySelector('.cca_workflow_header')?.dataset?.helpandsupportmodaltext)(),
    HELP_SUPPORT_MODAL_PRIMARY_BUTTON_LABEL: (() =>
        document.querySelector('.cca_workflow_header')?.dataset?.helpprimarybuttonlabel)(),
    HELP_SUPPORT_MODAL_SECONDARY_BUTTON_LABEL: (() =>
        document.querySelector('.cca_workflow_header')?.dataset?.helpsecondarybuttonlabel)(),
    HELP_SUPPORT_MODAL_SECONDARY_BUTTON_PHONE_NUMBER_LABEL: (() =>
        document.querySelector('.cca_workflow_header')?.dataset?.helpsecondarybuttonphonenumberlabel)(),

    EXIT_MODAL_MODAL_PRIMARY_BUTTON_LABEL: (() =>
        document.querySelector('.cca_workflow_header')?.dataset?.exitprimarybuttonlabel)(),
    EXIT_MODAL_MODAL_SECONDARY_BUTTON_LABEL: (() =>
        document.querySelector('.cca_workflow_header')?.dataset?.exitsecondarybuttonlabel)(),

    EXIT_MODAL_TITLE: (() => document.querySelector('.cca_workflow_header')?.dataset?.exitmodaltitle)(),
    EXIT_MODAL_TEXT: (() => document.querySelector('.cca_workflow_header')?.dataset?.exitmodaltext)(),
    LLP_LEGEND_SPECIALTY_STORE_CONTENT: (() =>
        document.querySelector('.location_landing_page')?.dataset?.specialtystoredescription || '')(),
    LLP_LEGEND_GT_STORE_CONTENT: (() =>
        document.querySelector('.location_landing_page')?.dataset?.defaultstoredescription || '')(),
    LLP_LEGEND_SPECIALTY_STORE_URL: (() =>
        document.querySelector('.location_landing_page')?.dataset?.specialtystoreurl ||
        '/content/sunbeltrentals/us/en_US/industries.html')(),
    LLP_LEGENDGT_GT_STORE_URL: (() =>
        document.querySelector('.location_landing_page')?.dataset?.defaultstoreurl ||
        '/content/sunbeltrentals/us/en_US/solutions/general-tool.html')(),
    LLP_RADIUS_FOR_DELIVERY_MESSAGE: (() =>
        document.querySelector('.location_landing_page')?.dataset?.storemaxradius)(),
    LLP_INVALID_ADDRESS_SEARCH_ERROR: (() =>
        document.querySelector('.location_landing_page')?.dataset?.invalidsearchaddresserror)(),
    LLP_TIMEZONE_OFFET: (() => document.querySelector('.location_landing_page')?.dataset?.timezoneoffsetjson || '{}')(),
    LDP_CAP_BOTTOM_TEXT: (() =>
        document.querySelector('.ldp-cap-header-aem')?.dataset?.letsgetstarted || "Let's get started")(),
    LDP_ALERT_MESSAGE: (() =>
        document.querySelector('.ldp_alert_message')?.dataset?.alertMessage || "This location is more than 100 miles from your jobsite address.")(),
    LDP_ALERT_MAX_DISTANCE: (() =>
        document.querySelector('.ldp_alert_message')?.dataset?.alertMaxDistance || "100")(),
    CAP_HEADER: (() => document.querySelector('.cap__header')?.dataset)(),
    CAP_HEADER_AEM_RIGHT_CONTENT: (() => document.querySelector('.cap-header-aem__right-content')?.dataset)(),
    NON_EDITABLE_BANNER_MESSAGE: (() =>
        document.querySelector('meta[name="editQuoteBannerMessage"')?.content ||
        'Because this quote was created by a Sunbelt Rentals employee, it can’t be edited.')(),
    NON_EDITABLE_BANNER_MESSAGE_PUNCHOUT: (() =>
        document.querySelector('meta[name="failedTransmissionWarningMessage"')?.content ||
        'Because transmission has already been attempted on this quote, it can’t be edited.')(),
    LLP_TEXT_FOR_DELIVERY_MESSAGE: (() => document.querySelector('.location_landing_page')?.dataset?.deliverymessage)(),
    RENTAL_LOCATION_TEXT: (() => document.querySelector('.cap__header')?.dataset?.rentallocationtext)(),
    START_DATE_TEXT: (() => document.querySelector('.cap__header')?.dataset?.startdatetext)(),
    END_DATE_TEXT: (() => document.querySelector('.cap__header')?.dataset?.enddatetext)(),
    DELIVERY_TEXT: (() => document.querySelector('.cap__header')?.dataset?.deliverytext)(),
    PICKUP_TEXT: (() => document.querySelector('.cap__header')?.dataset?.pickuptext)(),
    RENTAL_LABELS: (() => document.querySelector('.cap__header')?.dataset?.rentallabels || '{}')(),
    RENTING_CTA_TEXT: (() => document.querySelector('.cap__header')?.dataset?.rentingctatext)(),
    ADDRESS_NOT_FOUND_TITLE: (() => document.querySelector('.cap__header')?.dataset?.matchingaddressnotfoundtitle)(),
    ADDRESS_NOT_FOUND_TEXT: (() => document.querySelector('.cap__header')?.dataset?.matchingaddressnotfoundtext)(),
    ADDRESS_NOT_FOUND_HELPLINE: (() => document.querySelector('.cap__header')?.dataset?.addressnotfoundhelpline)(),
    ACCOUNT_TEXT: (() => document.querySelector('.cap-header-aem__right-content')?.dataset?.accounttext)(),
    CAP_LINK_TEXT: (() => document.querySelector('.cap-header-aem__right-content')?.dataset?.linktext)(),
    CAP_LOGO_SOURCE: (() => document.querySelector('.cap-header-aem__right-content')?.dataset?.signinlogo)(),
    IS_ATP_DISABLED: (() => document.querySelector('#inventoryCheckConfigs')?.dataset?.rollbackToCi || false)(),
    CAP_JSON: (() => document.querySelector('.cap__header')?.dataset?.json || null)(),
    P2P_MY_FAVORITES_CAROUSAL: (() => document.querySelector('.p2p__myFavoritesCarousal')?.dataset)(),
    COACHMARK_STATUS: (() => document.querySelector('#coachmarkFlagStatus')?.dataset || {})(),
    HEADER: (() => document?.querySelector('header'))(),
    APPLICATION_STATUS_TITLE: (() => applicationStatusLoadingDataSet?.headertitlelabel)(),
    APPLICATION_STATUS_TEXT: (() => applicationStatusLoadingDataSet?.applicationstatusmessage)(),
    APPLICATION_STATUS_CUSTOMER_SERVICE_LABEL: (() =>
        applicationStatusLoadingDataSet?.applicationstatuscustomerservicelabel)(),
    APPLICATION_STATUS_CUSTOMER_SERVICE_PHONE_NUMBER: (() =>
        applicationStatusLoadingDataSet?.applicationstatuscustomerservicephonenumber)(),

    HEADER_TITLE_LABEL: (() => applicationStatusApprovedDataSet?.headertitlelabel)(),
    APPLICATION_STATUS_MESSAGE: (() => applicationStatusApprovedDataSet?.applicationstatusmessage)(),
    BUTTON_LABEL: (() => applicationStatusApprovedDataSet?.buttonlabel)(),
    APPROVED_CUSTOMER_SERVICE_PATH: (() => applicationStatusApprovedDataSet?.approvedcustomerservicepath)(),
    APPLICATION_STATUS_ICON: (() => applicationStatusApprovedDataSet?.src)(),
    APPLICATION_STATUS_ALT_TEXT: (() => applicationStatusApprovedDataSet?.alttext)(),

    HEADER_TITLE_LABEL_REVIEW: (() => applicationStatusReviewDataSet?.headertitlelabel)(),
    APPLICATION_STATUS_MESSAGE_REVIEW: (() => applicationStatusReviewDataSet?.applicationstatusmessage)(),
    BUTTON_LABEL_REVIEW: (() => applicationStatusReviewDataSet?.buttonlabel)(),
    APPLICATION_STATUS_ICON_REVIEW: (() => applicationStatusReviewDataSet?.src)(),
    APPLICATION_STATUS_ALT_TEXT_REVIEW: (() => applicationStatusReviewDataSet?.alttext)(),
    CUSTOMER_SERVICE_NUMBER: (() => applicationStatusReviewDataSet?.customerservicenumber)(),
    CUSTOMER_SERVICE_LABEL: (() => applicationStatusReviewDataSet?.customerservicelabel)(),
    CUSTOMER_SERVICE_PATH: (() => applicationStatusReviewDataSet?.customerservicepath)(),

    HEADER_TITLE_LABEL_ERROR: (() => applicationStatusErrorDataSet?.headertitlelabel)(),
    APPLICATION_STATUS_MESSAGE_ERROR: (() => applicationStatusErrorDataSet?.applicationstatusmessage)(),
    BUTTON_LABEL_ERROR: (() => applicationStatusErrorDataSet?.buttonlabel)(),
    CUSTOMER_SERVICE_NUMBER_ERROR: (() => applicationStatusErrorDataSet?.customerservicenumber)(),
    APPLICATION_STATUS_ICON_ERROR: (() => applicationStatusErrorDataSet?.src)(),
    APPLICATION_STATUS_ALT_TEXT_ERROR: (() => applicationStatusErrorDataSet?.alttext)(),
    APPLICATION_ERROR_CODE_LABEL: (() => applicationStatusErrorDataSet?.errorcodelabel)(),
    APPLICATION_STATUS: (() => applicationStatusErrorDataSet?.applicationstatus)(),
    APPROVED_PAGE_URL: (() => ccaPageUrls?.approvedpageurl)(),
    WARNING_PAGE_URL: (() => ccaPageUrls?.warningpageurl)(),

    GR_HEADER_TITLE_LABEL_ERROR: (() => grApplicationStatusErrorDataSet?.headertitlelabel)(),
    GR_APPLICATION_STATUS_MESSAGE_ERROR: (() => grApplicationStatusErrorDataSet?.applicationstatusmessage)(),
    GR_CUSTOMER_SERVICE_NUMBER_ERROR: (() => grApplicationStatusErrorDataSet?.rejectioncustomerservicenumber)(),
    GR_APPLICATION_STATUS_ICON_ERROR: (() => grApplicationStatusErrorDataSet?.src)(),
    GR_APPLICATION_STATUS_ALT_TEXT_ERROR: (() => grApplicationStatusErrorDataSet?.alttext)(),

    MAIN_SECTION: (() =>
        document?.querySelector('main.container.responsivegrid.main-wrapper') ||
        document?.querySelector('div.container.responsivegrid.main-wrapper'))(),
    GR_CREATE_ACCOUNT: (() => document.querySelector('.gr__workflow')?.dataset)(),
    CASHFLOW_APPLICATION_STATUS: (() => document.querySelector('.applicationStatus__configs')?.dataset)(),
    CASH_WORKFLOW_DATA: (() => document.querySelector('.cash__workflow')?.dataset)(),
    CASH_WORKFLOW_DATA_PREVIEW_MODE: (() => document.querySelector('.cash__workflow')?.dataset?.previewmode)(),

    JOIN_WORKFLOW_DATA: (() => document.querySelector('.joinaccount__workflow')?.dataset)(),
    AUTORITY_TYPE: (() => 'dotcom')(),

    CART_CONFIGURATIONS: (() => document.querySelector('.cart section')?.dataset || {})(),
    BODY_TAG: (() => document.querySelector('body') || '')(),
    DISABLE_CURRENT_LOCATION_PROMPT_DESKTOP: (() =>
        document.querySelector('#inventoryCheckConfigs')?.dataset?.disablecurrentlocationpromptdesktop || false)(),
    DISABLE_CURRENT_LOCATION_PROMPT_TABLET: (() =>
        document.querySelector('#inventoryCheckConfigs')?.dataset?.disablecurrentlocationprompttablet || false)(),
    DISABLE_CURRENT_LOCATION_PROMPT_MOBILE: (() =>
        document.querySelector('#inventoryCheckConfigs')?.dataset?.disablecurrentlocationpromptmobile || false)(),
    EXISTING_QUOTE_TITLE: (() => document.querySelector('.p2p__findExistingQuotes')?.dataset?.title)(),
    EXISTING_QUOTE_PLACEHOLDER: (() =>
        document.querySelector('.p2p__findExistingQuotes')?.dataset?.searchPlaceholderLabel)(),
    EXISTING_QUOTE_DESCRIPTION: (() => document.querySelector('.p2p__findExistingQuotes')?.dataset?.description)(),
    EXISTING_QUOTE_LINK: (() => document.querySelector('.p2p__findExistingQuotes')?.dataset?.linktitle)(),
    EXISTING_QUOTE_PREVIOUS_QUOTE_PATH: (() =>
        document.querySelector('.p2p__findExistingQuotes')?.dataset?.previousquotepath)(),
    EXISTING_QUOTE_SEARCH_BTN: (() => document.querySelector('.p2p__findExistingQuotes')?.dataset?.searchCtaBtnLabel)(),
    EXISTING_QUOTE_LINK_TARGET: (() => document.querySelector('.p2p__findExistingQuotes')?.dataset?.quoteLinkTarget)(),
    FAVOURITE_NEW_LIST_LABEL: (() => document.querySelector('.P2P_React_Mount')?.dataset?.favoritenewlistlabel)(),
    MANAGE_PO_TITLE: (() => document.querySelector('p2p__managePurchaseOrder')?.dataset?.title)(),
    MANAGE_PO_DESCRIPTION: (() => document.querySelector('p2p__managePurchaseOrder')?.dataset?.description)(),
    MANAGE_PO_BTN_TITLE: (() => document.querySelector('p2p__managePurchaseOrder')?.dataset?.btnTitle)(),
    MANAGE_PO_PAGE_PATH: (() => document.querySelector('p2p__managePurchaseOrder')?.dataset?.pagePath)(),
    MANAGE_PO_LINK_TARGET: (() => document.querySelector('p2p__managePurchaseOrder')?.dataset?.linkTarget)(),
    BREADCRUMB_SECTION: (() =>
        document?.querySelector('main.container.responsivegrid.main-wrapper') ||
        document?.querySelector('div.container.responsivegrid.main-wrapper'))(),
    HIGHER_FEE_WARNING: (() => document?.querySelector('#p2pMiniCartConfigs')?.dataset?.minicarterrormessage)(),
    HIGHER_FEE_WARNING_DESCRIPTION: (() =>
        document?.querySelector('#p2pMiniCartConfigs')?.dataset?.tooltipdescription)(),
    STREET_VIEW_IMAGE_CONFIG: (()=> document.querySelector('.streetViewAttributes')?.dataset)(),
    PRODUCT_RECOMMENDATIONS_MINIMUM_PRODUCTS: (() => document.querySelector('.block--productrecommendations')?.dataset?.minproductslength || "4")(),
    PRODUCT_TILE_FIRST : (() => document?.querySelector(".producttile__top"))(),
    GREEN_LEAF_CONFIGS: (() => document.querySelector('#greenleafconfigs')?.dataset)(),
    DYNAMIC_SEASONAL_PRODUCTS_DATASET : (() => document.querySelector('.dynamic_seasonal_products')?.dataset || {})()
};
