import { createLazyImports } from '../../global/lazyLoad/lazyLoadUtils';

const componentPaths = {
    LazySearchBox: () => import(/* webpackChunkName: "cmp-SearchBox" */ '../srp/SearchBox'),
    LazySearchTypeAhead: () =>
        import(/* webpackChunkName: "cmp-SearchTypeAhead" */ '../Search').then(module => ({
            default: module.SearchTypeAhead
        })),
    LazyRelatedResults: () => import(/* webpackChunkName: 'cmp-RelatedResults' */ '../srp/RelatedResults'),
    LazyBannerSearch: () => import(/* webpackChunkName: "cmp-BannerSearch" */ '../../search/typahead/BannerSearch'),
    LazyHeaderSearch: () => import(/* webpackChunkName: "cmp-HeaderSearch" */ '../../search/typahead/HeaderSearch'),
    DataBricks: () => import(/* webpackChunkName: 'cmp-DataBricks' */ '../../global/modules/dataBricks/DataBricks'),
    SearchResultsPage: () => import(/* webpackChunkName: 'cmp-SearchResultsPage' */ '../../search/srp/SearchResults'),
    PlpPage: () => import(/* webpackChunkName: 'cmp-PlpPage' */ '../../search/plp/Plp')
};

const LazyLoadSearchImports = createLazyImports(componentPaths);

export const {
    LazySearchBox,
    LazySearchTypeAhead,
    LazyRelatedResults,
    LazyBannerSearch,
    LazyHeaderSearch,
    DataBricks,
    SearchResultsPage,
    PlpPage
} = LazyLoadSearchImports;